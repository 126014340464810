import env from "@/config/env"
import { ApiService } from "../base"
import { mockServer } from "@/utils/mock-server"

class QuicksiteService extends ApiService {
    constructor() {
        super({
            baseURL: `${env.QUICKSITE_API_PATH}`,
        })
    }

    @mockServer(`//quicksite`)
    publish() {
        return this.post("/publish")
    }
}

export default new QuicksiteService()

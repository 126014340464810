import { TitleWrapper } from "@/components/base/title-wrapper"
import AttributeAccordion from "@/components/features/business/accordion"
import EditAttributeContainer from "@/components/features/business/form-container/edit/edit-attribute"
import FormAction from "@/components/features/business/form-container/edit/form-action"
import useLogic from "@/hooks/businesses/edit-attribute/useLogic"
import { PageProps } from "@/types/page"
import { Alert, AlertTitle, Box, Button, Stack, Typography } from "@mui/material"

const EditAttribute = ({ onClose, open }: PageProps) => {
    const {
        handlePreviousButton,
        t,
        handleSubmit,
        handleCancelButton,
        onSubmit,
        onErrors,
        loading,
        editedAttributes,
        attributeUnavailable,
    } = useLogic()

    return (
        <EditAttributeContainer open={open} onClose={onClose}>
            <TitleWrapper
                data-testid="title"
                handlePreviousButton={handlePreviousButton}
                title={t("BUSINESSES.EDIT_ATTRIBUTES.TITLE")}
            />

            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                <Box className="inputs-container">
                    {attributeUnavailable && (
                        <Alert severity="error" variant="filled" sx={{ flexDirection: "row", gap: 1 }}>
                            <Stack>
                                <AlertTitle
                                    sx={{
                                        marginBottom: 0,
                                    }}
                                >
                                    {t("BUSINESSES.EDIT_ATTRIBUTES.DISPLAY_IMPOSSIBLE")}
                                </AlertTitle>
                                <Stack gap={1} flexDirection={"column"} alignItems={"flex-start"}>
                                    <Typography variant="body2" color={"#FFF"}>
                                        {t("BUSINESSES.EDIT_ATTRIBUTES.DISPLAY_IMPOSSIBLE_DESC")}
                                    </Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        sx={{
                                            color: "error.main",
                                            background: "#FFF",
                                        }}
                                        onClick={() => window.location.reload()}
                                    >
                                        {t("BUTTON.RETRY")}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Alert>
                    )}
                    {Array.isArray(editedAttributes) &&
                        editedAttributes.map((item, key) => (
                            <AttributeAccordion item={item} key={item?.title} itemKey={key} />
                        ))}
                </Box>
                <FormAction handleCancel={handleCancelButton} isLoading={loading} isDisabled={loading} isUpdating />
            </form>
        </EditAttributeContainer>
    )
}

export default EditAttribute

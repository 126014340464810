import env from "@/config/env"

export function mockServer(basePath?: string) {
    return function (target: any, key: string | symbol, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value

        descriptor.value = function (...args: any[]) {
            // Store the original client
            const originalClient = this.client
            try {
                // Override client with the provided mock server URL or fallback to env.MOCK_SERVER
                this.client.defaults.baseURL = `${env.MOCK_SERVER}${basePath}`
                // Execute the original method with the new mock client
                return originalMethod.apply(this, args)
            } finally {
                // Restore original client after execution
                this.client = originalClient
            }
        }

        return descriptor
    }
}

import Icon from "@/components/base/Icon"
import FormAction from "@/components/base/form-action"
import { TitleWrapper } from "@/components/base/title-wrapper"
import AddMediaModal from "@/components/features/business/add-media-modal"
import ListMediaContainer from "@/components/features/business/form-container/list-media"
import { DeleteModal } from "@/components/widget/modal-delete"
import useListMediaLogic from "@/hooks/businesses/list-media/useLogic"
import { Box, Button, CardMedia, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material"
import { Fragment } from "react"

const imageSize = window.innerWidth < 768 ? 159 : 172

export const BtnActionMedia = ({ t, handleSelectMedia, handleModalRemove, name }) => {
    return (
        <Box className={name}>
            <Tooltip title={t("MEDIA.MODIFY_TOOLTIP")} placement="top">
                <IconButton onClick={handleSelectMedia}>
                    <Icon name="edit" className="edit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t("MEDIA.DELETE_TOOLTIP")} placement="top">
                <IconButton onClick={handleModalRemove} data-testid="delete-modal-button">
                    <Icon name="delete1" sx={{ color: "error.main" }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}

const ListMedia = ({ open, onClose }) => {
    const {
        loading,
        handlePreviousButton,
        handleCancelButton,
        t,
        openAddMediaModal,
        onCloseAddMediaModal,
        onOpenAddMediaModal,
        handleModalRemove,
        openRemoveModal,
        handleImage,
        inputRef,
        inputChangeHandler,
        handleSubmit,
        onSubmit,
        onErrors,
        error,
        listMedia,
        listMediaLocal,
        handleSelectMedia,
        isLoading,
    } = useListMediaLogic()

    return (
        <>
            <ListMediaContainer open={open} onClose={onClose}>
                <TitleWrapper handlePreviousButton={handlePreviousButton} title={t("MEDIA.MODIFY_SUBTITLE")} />
                <form>
                    <Box className="inputs-container">
                        <Paper className="search-wrapper">
                            <Button
                                className="add-btn"
                                data-e2e="add-media"
                                color="primary"
                                variant="contained"
                                onClick={onOpenAddMediaModal}
                            >
                                <Icon name="plus" />
                                <Typography component="span">{t("MEDIA.ADD")}</Typography>
                            </Button>
                        </Paper>
                        <Box sx={{ flexGrow: 1 }}>
                            <Paper className="media-container">
                                <Stack gap={2} flexDirection={"row"} alignItems={"center"}>
                                    <Icon name="imagePlaceholder" />
                                    <Typography variant="h1" component="h2">
                                        {t("MEDIA.TITLE")}
                                    </Typography>
                                </Stack>

                                {listMediaLocal?.length > 0 && (
                                    <Stack gap={{ xs: 1, sm: 2 }} direction={"row"} flexWrap="wrap">
                                        {listMediaLocal?.map((image, k) => (
                                            <Fragment key={image.file.name}>
                                                {image.file && image.type === "PHOTO" && (
                                                    <Box
                                                        className="img-wrapper"
                                                        minWidth={imageSize}
                                                        width={imageSize}
                                                        height={imageSize}
                                                        data-testid="image-wrapper"
                                                    >
                                                        <img
                                                            src={URL.createObjectURL(
                                                                image.fileCrop ? image.fileCrop : image.file
                                                            )}
                                                            alt="Logo"
                                                        />
                                                        <BtnActionMedia
                                                            t={t}
                                                            handleModalRemove={() => handleModalRemove(null, k, true)}
                                                            handleSelectMedia={() => handleSelectMedia(image)}
                                                            name={"image-actions-local"}
                                                        />
                                                    </Box>
                                                )}
                                                {image.file && image.type === "VIDEO" && (
                                                    <Box
                                                        className="img-wrapper"
                                                        minWidth={imageSize}
                                                        width={imageSize}
                                                        height={imageSize}
                                                        data-testid="image-wrapper"
                                                    >
                                                        <CardMedia
                                                            style={{ backgroundColor: "gray", borderRadius: 8 }}
                                                            height={imageSize}
                                                            component="video"
                                                            image={URL.createObjectURL(image.file)}
                                                        />
                                                        {<Icon name="play" className="play" />}
                                                        <Box className="image-actions-local">
                                                            <IconButton onClick={() => handleSelectMedia(image)}>
                                                                <Icon name="edit" className="edit" />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => handleModalRemove(null, k, true)}
                                                            >
                                                                <Icon name="delete1" sx={{ color: "error.main" }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Fragment>
                                        ))}
                                    </Stack>
                                )}
                                {(!listMediaLocal || listMediaLocal.length === 0) && listMedia.length > 0 && (
                                    <Stack gap={{ xs: 1, sm: 2 }} direction={"row"} flexWrap="wrap">
                                        {listMedia.map((image, k) => (
                                            <Box
                                                className="img-wrapper"
                                                minWidth={imageSize}
                                                width={imageSize}
                                                height={imageSize}
                                                key={image.id}
                                                data-testid="image-wrapper"
                                            >
                                                <img
                                                    src={image.thumbnailUrl ? image.thumbnailUrl : image.url}
                                                    alt="media"
                                                />

                                                {image.type === "VIDEO" && <Icon name="play" className="play" />}
                                                <BtnActionMedia
                                                    t={t}
                                                    handleModalRemove={() => handleModalRemove(image, k, true)}
                                                    handleSelectMedia={() => handleSelectMedia(image)}
                                                    name={"image-actions"}
                                                />
                                            </Box>
                                        ))}
                                    </Stack>
                                )}
                            </Paper>
                        </Box>
                    </Box>
                    {listMediaLocal?.length > 0 && (
                        <FormAction
                            handleCancel={handleCancelButton}
                            isLoading={loading}
                            isDisabled={loading}
                            isUpdating
                            sx={formActionSx}
                            onSubmit={handleSubmit(onSubmit, onErrors)}
                        />
                    )}
                </form>
            </ListMediaContainer>
            <DeleteModal
                open={openRemoveModal}
                onClose={() => handleModalRemove(null, null, false)}
                title={t("MEDIA.DELETE_TOOLTIP")}
                description={t("MEDIA.CONFIRM_DELETE")}
                loading={isLoading}
                onDelete={() => handleModalRemove(null, null, true)}
            />
            <AddMediaModal
                open={openAddMediaModal}
                onClose={onCloseAddMediaModal}
                error={error}
                handleImage={handleImage}
                inputRef={inputRef}
                inputChangeHandler={inputChangeHandler}
            />
        </>
    )
}

export default ListMedia

const formActionSx = { "@media (min-width: 768px)": { mt: 2 } }
